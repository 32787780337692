import { useContext, useEffect, useState } from 'react';
import { ResultDto, UserContext, abortableFetch } from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';

const getCount = async (
  url: string,
  field = 'state.id',
  value = 'NEW'
): Promise<ResultDto<unknown>> =>
  abortableFetch(url + '/list', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filters: [{ field, value, operation: 'EQ' }],
      size: 1,
    }),
  }).json();

type Endpoint = {
  url: string;
  id: string;
  field?: string;
  value?: string;
  permissions: Permission[];
};

const endpoints: Endpoint[] = [
  {
    //---AnonymousSubmission - Anonymní podnet---
    id: 'AnonymousSubmission',
    url: EvidenceApiUrl.ANONYMOUS_SUBMISSION,
    permissions: [
      Permission.BO_ANONYMOUS_SUBMISSION_MANAGE,
      Permission.BO_ANONYMOUS_SUBMISSION_READ,
    ],
  },
  {
    //---AuditReport - Auditorská činnost---
    id: 'AuditReport',
    url: EvidenceApiUrl.AUDIT_REPORT,
    permissions: [
      Permission.BO_AUDIT_REPORT_MANAGE,
      Permission.BO_AUDIT_REPORT_READ,
    ],
  },
  {
    //---InsuranceSubmission
    id: 'InsuranceSubmission',
    url: EvidenceApiUrl.INSURANCE_SUBMISSION,
    permissions: [
      Permission.BO_INSURANCE_SUBMISSION_MANAGE,
      Permission.BO_INSURANCE_SUBMISSION_READ,
    ],
  },
  {
    //---EducationSubmission
    id: 'EducationSubmission',
    url: EvidenceApiUrl.EDUCATION_SUBMISSION,
    permissions: [
      Permission.BO_EDUCATION_SUBMISSION_MANAGE,
      Permission.BO_EDUCATION_SUBMISSION_READ,
    ],
  },
  {
    //---AuditNotice
    id: 'AuditNotice',
    url: EvidenceApiUrl.AUDIT_NOTICE,
    permissions: [
      Permission.BO_AUDIT_NOTICE_MANAGE,
      Permission.BO_AUDIT_NOTICE_READ,
    ],
  },
  {
    //---AuditRegistration
    id: 'AuditRegistration',
    url: EvidenceApiUrl.AUDIT_REGISTRATION,
    permissions: [
      Permission.BO_AUDIT_REGISTRATION_MANAGE,
      Permission.BO_AUDIT_REGISTRATION_READ,
    ],
  },
  {
    //---GeneralSubmission
    id: 'GeneralSubmission',
    url: EvidenceApiUrl.GENERAL_SUBMISSION,
    value: 'SUBMITTED',
    permissions: [
      Permission.BO_GENERAL_SUBMISSION_MANAGE,
      Permission.BO_GENERAL_SUBMISSION_READ,
    ],
  },
  {
    //---LicenceSubmission
    id: 'LicenceSubmission',
    url: EvidenceApiUrl.LICENCE_APPLICATION,
    permissions: [
      Permission.BO_LICENCE_APPLICATION_MANAGE,
      Permission.BO_LICENCE_APPLICATION_READ,
    ],
  },
];

export function useSubmissionsCountsForMenu() {
  const [counts, setCounts] = useState<Record<string, string>>({});
  const { hasPermission } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      const promises = endpoints
        .filter((e) =>
          e.permissions.some((permission) => hasPermission(permission))
        )
        .map((endpoint) => {
          return {
            promise: getCount(endpoint.url, endpoint.field, endpoint.value),
            id: endpoint.id,
          };
        });

      const results = await Promise.allSettled(promises.map((p) => p.promise));
      const pairs = results.map((r, i) => ({
        id: endpoints[i].id,
        count: r.status === 'fulfilled' ? r.value?.count : null,
        error: r.status !== 'fulfilled',
      }));

      const counts = Object.fromEntries(
        pairs.map((p) => [p.id, p.count !== null ? `(${p.count})` : ''])
      );
      setCounts(counts);
      setLoading(false);
    };
    setLoading(true);
    load();
  }, [hasPermission]);
  return { counts, loading };
}
