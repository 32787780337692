import * as Yup from 'yup';
import { Address, DictCountry } from '@models';

export function useAddressValidationSchema() {
  return Yup.object<Address>().shape({
    houseNumber: Yup.string().nullable().required(),
    // street: Yup.string().nullable(),
    zip: Yup.string().nullable().required(),
    municipality: Yup.string().nullable().required(),
    country: Yup.mixed<DictCountry>().nullable().required(),
  });
}
