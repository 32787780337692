import { useContext } from 'react';
import * as Yup from 'yup';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useValidationSchema } from '@modules/financial-statement-audit/financial-statement-audit-schema';
import {
  AuditReport,
  FinancialStatementAudit,
  FinancialStatementAuditReport,
} from '@models';
import {
  AuditReportState,
  AuditingEntityType,
  EvidenceApiUrl,
  Permission,
} from '@enums';

export function useProcessDialog() {
  const { source } = useContext<DetailHandle<AuditReport>>(DetailContext);
  const { hasPermission } = useContext(UserContext);
  const financialStatementAuditSchema = useValidationSchema();

  const isOwnerAuditor =
    source?.data?.owner?.type === AuditingEntityType.AUDITOR;

  const apiCall = useEventCallback((id: string, formData) =>
    abortableFetch(`${EvidenceApiUrl.AUDIT_REPORT}/${id}/process`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
      body: JSON.stringify({
        financialStatementAudits: isOwnerAuditor
          ? formData.financialStatements
          : formData.financialStatements.map((f: FinancialStatementAudit) => ({
              ...f,
              auditingEntities: [
                ...(f?.auditingEntities ?? []),
                source?.data?.owner,
              ],
            })),
      }),
    })
  );

  const showBtn =
    hasPermission(Permission.BO_AUDIT_REPORT_MANAGE) &&
    source?.data?.state === AuditReportState.NEW;

  const formValidationSchema = Yup.object().shape({
    financialStatements: Yup.array()
      .nullable()
      .test('', '', async function (value) {
        const { createError, path } = this;
        const audits = value as FinancialStatementAudit[];

        try {
          if (audits?.length === 0) {
            return true;
            // return createError({
            //   path,
            //   message: 'Musí existovať aspoň jedna účtovná závierka',
            // });
          }

          for (const audit of audits) {
            await financialStatementAuditSchema.validate(audit);
          }
        } catch (err) {
          return createError({
            path,
            message: 'Doplňte chýbajúce povinné pole všetkých záznamov',
          });
        }

        return true;
      }),
  });

  return {
    apiCall,
    showProcessBtn: showBtn,
    formValidationSchema,
    formInitialValues: {
      financialStatements: source?.data?.financialStatements?.map((f) => ({
        ...f,
        auditingEntityAccountingEndDate:
          source?.data?.auditingEntityAccountingEndDate,
        note: f.portalNote,
        auditingEntities: f?.auditingAuditors,
      })) as FinancialStatementAuditReport[],
    },
  };
}
