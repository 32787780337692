import { isAfter } from 'date-fns';
import * as Yup from 'yup';
import { useAddressValidationSchema } from '@components/form/address-panel/address-schema';
import { PersonalContact, SubjectNatural } from '@models';

export function useValidationSchema() {
  const validateAddress = useAddressValidationSchema();

  return Yup.object<SubjectNatural>().shape({
    firstName: Yup.string().nullable().required(),
    lastName: Yup.string().nullable().required(),
    birthDate: Yup.date()
      .nullable()
      .test('', '', function (value) {
        const { path, createError } = this;

        if (value && isAfter(value, new Date())) {
          return createError({ path, message: 'Musí byť v minulosti' });
        }

        return true;
      }),
    address: validateAddress,
    contacts: Yup.array<PersonalContact>(),
    //TODO: zistit ake kriteria tu maju byt
    // .test('', '', function (value) {
    //   const { createError, path } = this;
    //   const contacts = (value ?? []) as PersonalContact[];

    //   /*      if (!contacts.some((c) => c.type === PersonContactType.MAIN_EMAIL)) {
    //     return createError({
    //       path,
    //       message: 'Musí byť vyplnený hlavný e-mail',
    //     });
    //   }*/

    //   return true;
    // }),
  });
}
