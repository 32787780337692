import { compact } from 'lodash';
import { useContext, useMemo } from 'react';
import {
  MenuItem,
  NavigationContext,
  UserBtnAction,
  UserContext,
  useWebsocketEffect,
} from '@eas/common-web';
import { EvidenceBrowserUrl, Permission } from '@enums';
import { useSubmissionsCountsForMenu } from './menu-get-counts';

export type UdvaMenuItem = MenuItem & {
  permission?: string[];
  items?: UdvaMenuItem[];
};

export function useMenuItems() {
  const { navigate } = useContext(NavigationContext);
  const { reload, hasPermission, user, logout } = useContext(UserContext);

  const { counts, loading } = useSubmissionsCountsForMenu();

  useWebsocketEffect(() => {
    reload();
    console.log('message SESSION_REFRESHED');
  }, ['SESSION_REFRESHED']);

  useWebsocketEffect(() => {
    logout();
    console.log('message SESSION_DESTROYED');
  }, ['SESSION_DESTROYED']);

  const items: UdvaMenuItem[] = compact([
    {
      label: 'Audítori',
      isActive: (pathname) => {
        return (
          !![EvidenceBrowserUrl.AUDITOR].find((url) =>
            pathname.includes(url)
          ) && !pathname.includes(EvidenceBrowserUrl.AUDIT_COMPANY)
        );
      },
      onClick: () => navigate(EvidenceBrowserUrl.AUDITOR),
      href: EvidenceBrowserUrl.AUDITOR,
      permission: [Permission.BO_AUDITING_ENTITY_READ],
    },
    {
      label: 'Audítorské spoločnosti',
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.AUDIT_COMPANY].find((url) =>
          pathname.includes(url)
        );
      },
      onClick: () => navigate(EvidenceBrowserUrl.AUDIT_COMPANY),
      href: EvidenceBrowserUrl.AUDIT_COMPANY,
      permission: [Permission.BO_AUDITING_ENTITY_READ],
    },
    {
      label: 'Poistenia',
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.INSURANCE].find((url) =>
          pathname.includes(url)
        );
      },
      onClick: () => navigate(EvidenceBrowserUrl.INSURANCE),
      href: EvidenceBrowserUrl.INSURANCE,
      permission: [Permission.BO_INSURANCE_READ],
    },
    {
      label: 'Subjekty',
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.NATURAL_PERSON,
          EvidenceBrowserUrl.LEGAL_PERSON,
        ].find((url) => pathname.includes(url));
      },
      items: [
        {
          label: 'Fyzické osoby',

          onClick: () => navigate(EvidenceBrowserUrl.NATURAL_PERSON),
          href: EvidenceBrowserUrl.NATURAL_PERSON,
          permission: [Permission.BO_SUBJECT_READ],
        },
        {
          label: 'Právnické osoby',

          onClick: () => navigate(EvidenceBrowserUrl.LEGAL_PERSON),
          href: EvidenceBrowserUrl.LEGAL_PERSON,
          permission: [Permission.BO_SUBJECT_READ],
        },
      ],
    },
    {
      label: 'Audity účtovných závierok',
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.FINANCIAL_STATEMENT_AUDIT].find((url) =>
          pathname.includes(url)
        );
      },
      onClick: () => navigate(EvidenceBrowserUrl.FINANCIAL_STATEMENT_AUDIT),
      href: EvidenceBrowserUrl.FINANCIAL_STATEMENT_AUDIT,
      permission: [Permission.BO_FIN_STATEMENT_AUDIT_READ],
    },
    {
      label: 'Skúšky',
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.EXAM,
          EvidenceBrowserUrl.EXAM_APPLICATION,
        ].find((url) => pathname.includes(url));
      },
      items: [
        {
          label: 'Skúšky',
          onClick: () => navigate(EvidenceBrowserUrl.EXAM),
          href: EvidenceBrowserUrl.EXAM,
          permission: [Permission.BO_EXAM_READ],
        },
        {
          label: 'Žiadosti o skúšku',
          onClick: () => navigate(EvidenceBrowserUrl.EXAM_APPLICATION),
          href: EvidenceBrowserUrl.EXAM_APPLICATION,
          permission: [Permission.BO_EXAM_APPLICATION_READ],
        },
      ],
    },
    {
      label: 'Dokumenty',
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.DOCUMENT].find((url) =>
          pathname.includes(url)
        );
      },
      onClick: () => navigate(EvidenceBrowserUrl.DOCUMENT),
      href: EvidenceBrowserUrl.DOCUMENT,
      permission: [Permission.BO_DOCUMENT_READ],
    },
    {
      label: 'Vzdelávania',
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.EDUCATION,
          EvidenceBrowserUrl.EDUCATION_CYCLE,
          EvidenceBrowserUrl.EDUCATIONAL_TRAINING,
          EvidenceBrowserUrl.EDUCATION_OVERVIEW,
        ].find((url) => pathname.includes(url));
      },
      items: [
        {
          label: 'Prehľad vzdelávania',
          onClick: () => navigate(EvidenceBrowserUrl.EDUCATION_OVERVIEW),
          href: EvidenceBrowserUrl.EDUCATION_OVERVIEW,
          permission: [Permission.BO_EDUCATION_READ],
        },
        {
          label: 'Školenia',
          onClick: () => navigate(EvidenceBrowserUrl.EDUCATIONAL_TRAINING),
          href: EvidenceBrowserUrl.EDUCATIONAL_TRAINING,
          permission: [Permission.BO_EDUCATION_READ],
        },
        {
          label: 'Vzdelávania',
          onClick: () => navigate(EvidenceBrowserUrl.EDUCATION),
          href: EvidenceBrowserUrl.EDUCATION,
          permission: [Permission.BO_EDUCATION_READ],
        },
        {
          label: 'Vzdelávací cyklus',
          onClick: () => navigate(EvidenceBrowserUrl.EDUCATION_CYCLE),
          href: EvidenceBrowserUrl.EDUCATION_CYCLE,
          permission: [Permission.BO_EDUCATION_READ],
        },
      ],
    },
    {
      label: 'Registratúrné záznamy',
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.REGISTRY_RECORD].find((url) =>
          pathname.includes(url)
        );
      },
      onClick: () => navigate(EvidenceBrowserUrl.REGISTRY_RECORD),
      href: EvidenceBrowserUrl.REGISTRY_RECORD,
      permission: [Permission.BO_REGISTRY_RECORD_READ],
    },
    {
      label: 'Udalosti v kalendári',
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.CALENDAR_EVENT].find((url) =>
          pathname.includes(url)
        );
      },
      onClick: () => navigate(EvidenceBrowserUrl.CALENDAR_EVENT),
      href: EvidenceBrowserUrl.CALENDAR_EVENT,
      permission: [Permission.BO_CALENDAR_EVENT_READ],
    },
    {
      label: 'Certifikáty a licencie',
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.CERTIFICATION,
          EvidenceBrowserUrl.LICENCE,
          EvidenceBrowserUrl.QUALIFICATION,
        ].find((url) => pathname.includes(url));
      },
      items: [
        {
          label: 'Certifikáty',
          onClick: () => navigate(EvidenceBrowserUrl.CERTIFICATION),
          href: EvidenceBrowserUrl.CERTIFICATION,
          permission: [Permission.BO_CERTIFICATION_READ],
        },
        {
          label: 'Licencie',
          onClick: () => navigate(EvidenceBrowserUrl.LICENCE),
          href: EvidenceBrowserUrl.LICENCE,
          permission: [Permission.BO_LICENCE_READ],
        },
        {
          label: 'Odbornosti',
          onClick: () => navigate(EvidenceBrowserUrl.QUALIFICATION),
          href: EvidenceBrowserUrl.QUALIFICATION,
          permission: [Permission.BO_QUALIFICATION_READ],
        },
      ],
    },
    {
      label: 'Platby a poplatky',
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.PAYMENT,
          EvidenceBrowserUrl.PAYMENT_PARSE,
          EvidenceBrowserUrl.CHARGE,
        ].find((url) => pathname.includes(url));
      },
      items: [
        {
          label: 'Platby',
          onClick: () => navigate(EvidenceBrowserUrl.PAYMENT),
          href: EvidenceBrowserUrl.PAYMENT,
          permission: [Permission.BO_PAYMENT_READ],
        },
        {
          label: 'Párovanie platieb',
          onClick: () => navigate(EvidenceBrowserUrl.PAYMENT_PARSE),
          href: EvidenceBrowserUrl.PAYMENT_PARSE,
          permission: [Permission.BO_PAYMENT_READ],
        },
        {
          label: 'Poplatky',
          onClick: () => navigate(EvidenceBrowserUrl.CHARGE),
          href: EvidenceBrowserUrl.CHARGE,
          permission: [Permission.BO_CHARGE_READ],
        },
      ],
    },
    {
      label: 'Používatelia',
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.USER].find((url) =>
          pathname.includes(url)
        );
      },
      items: [
        {
          label: 'Používatelia',
          onClick: () => navigate(EvidenceBrowserUrl.USER),
          href: EvidenceBrowserUrl.USER,
          permission: [Permission.BO_UDVA_USER_READ],
        },
        {
          label: 'Role',
          onClick: () => navigate(EvidenceBrowserUrl.ROLE),
          href: EvidenceBrowserUrl.ROLE,
          permission: [Permission.BO_USER_ROLE_READ],
        },
      ],
    },
    //#region PODANIA
    {
      label: 'Podania',
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.ANONYMOUS_SUBMISSION,
          EvidenceBrowserUrl.INSURANCE_SUBMISSION,
          EvidenceBrowserUrl.GENERAL_SUBMISSION,
        ].find((url) => pathname.includes(url));
      },
      items: loading
        ? [
            {
              label: `Anonymný podnet ${counts['AnonymousSubmission'] ?? ''}`,
              onClick: () => navigate(EvidenceBrowserUrl.ANONYMOUS_SUBMISSION),
              href: EvidenceBrowserUrl.ANONYMOUS_SUBMISSION,
              permission: [Permission.BO_ANONYMOUS_SUBMISSION_READ],
            },
          ]
        : [
            {
              label: `Anonymný podnet ${counts['AnonymousSubmission'] ?? ''}`,
              onClick: () => navigate(EvidenceBrowserUrl.ANONYMOUS_SUBMISSION),
              href: EvidenceBrowserUrl.ANONYMOUS_SUBMISSION,
              permission: [Permission.BO_ANONYMOUS_SUBMISSION_READ],
            },
            {
              label: `Audítorská činnosť ${counts['AuditReport'] ?? ''}`,
              onClick: () => navigate(EvidenceBrowserUrl.AUDIT_REPORT),
              href: EvidenceBrowserUrl.AUDIT_REPORT,
              permission: [Permission.BO_AUDIT_REPORT_READ],
            },
            {
              label: `Nahlásenie poistenia ${
                counts['InsuranceSubmission'] ?? ''
              }`,
              onClick: () => navigate(EvidenceBrowserUrl.INSURANCE_SUBMISSION),
              href: EvidenceBrowserUrl.INSURANCE_SUBMISSION,
              permission: [Permission.BO_INSURANCE_SUBMISSION_READ],
            },
            {
              label: `Ohlásenie vzdelávania ${
                counts['EducationSubmission'] ?? ''
              }`,
              onClick: () => navigate(EvidenceBrowserUrl.EDUCATION_SUBMISSION),
              href: EvidenceBrowserUrl.EDUCATION_SUBMISSION,
              permission: [Permission.BO_EDUCATION_SUBMISSION_READ],
            },
            {
              label: `Prerušenie/zahájenie činnosti ${
                counts['AuditNotice'] ?? ''
              }`,
              onClick: () => navigate(EvidenceBrowserUrl.AUDIT_NOTICE),
              href: EvidenceBrowserUrl.AUDIT_NOTICE,
              permission: [Permission.BO_AUDIT_NOTICE_READ],
            },
            {
              label: `Registrácia vykonávania auditu ${
                counts['AuditRegistration'] ?? ''
              }`,
              onClick: () => navigate(EvidenceBrowserUrl.AUDIT_REGISTRATION),
              href: EvidenceBrowserUrl.AUDIT_REGISTRATION,
              permission: [Permission.BO_AUDIT_REGISTRATION_READ],
            },
            {
              label: `Všeobecný podnet ${counts['GeneralSubmission'] ?? ''}`,
              onClick: () => navigate(EvidenceBrowserUrl.GENERAL_SUBMISSION),
              href: EvidenceBrowserUrl.GENERAL_SUBMISSION,
              permission: [Permission.BO_GENERAL_SUBMISSION_READ],
            },
            {
              label: `Žiadosť o zápis na zoznam ŠA/AS ${
                counts['LicenceSubmission'] ?? ''
              }`,
              onClick: () => navigate(EvidenceBrowserUrl.LICENCE_APPLICATION),
              href: EvidenceBrowserUrl.LICENCE_APPLICATION,
              permission: [Permission.BO_LICENCE_APPLICATION_READ],
            },
          ],
    },
    {
      label: 'Notifikácie',
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.NOTIFICATION].find((url) =>
          pathname.includes(url)
        );
      },
      onClick: () => navigate(EvidenceBrowserUrl.NOTIFICATION),
      href: EvidenceBrowserUrl.NOTIFICATION,
      permission: [Permission.SYSTEM_ADMINISTRATION],
    },
    {
      label: 'Číselníky',
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.DICT_COUNTRY,
          EvidenceBrowserUrl.DICT_EDUCATION_FORM,
          EvidenceBrowserUrl.DICT_MONETARY_CURRENCY,
          EvidenceBrowserUrl.DICT_INSURANCE_COMPANY,
        ].find((url) => pathname.includes(url));
      },
      items: [
        {
          label: 'Finančné meny',
          onClick: () => navigate(EvidenceBrowserUrl.DICT_MONETARY_CURRENCY),
          href: EvidenceBrowserUrl.DICT_MONETARY_CURRENCY,
          permission: [Permission.BO_CURRENCY_READ],
        },
        {
          label: 'Formy vzdelávania',
          onClick: () => navigate(EvidenceBrowserUrl.DICT_EDUCATION_FORM),
          href: EvidenceBrowserUrl.DICT_EDUCATION_FORM,
          permission: [Permission.BO_EDUCATION_MANAGE],
        },
        {
          label: 'Krajiny',
          onClick: () => navigate(EvidenceBrowserUrl.DICT_COUNTRY),
          href: EvidenceBrowserUrl.DICT_COUNTRY,
          permission: [Permission.BO_COUNTRY_READ],
        },
        {
          label: 'Oblasť vzdelávania',
          onClick: () => navigate(EvidenceBrowserUrl.DICT_EDUCATION_FIELD),
          href: EvidenceBrowserUrl.DICT_EDUCATION_FIELD,
          permission: [Permission.BO_EDUCATION_MANAGE],
        },
        {
          label: 'Poisťovne',
          onClick: () => navigate(EvidenceBrowserUrl.DICT_INSURANCE_COMPANY),
          href: EvidenceBrowserUrl.DICT_INSURANCE_COMPANY,
          permission: [Permission.BO_INSURANCE_COMPANY_READ],
        },
        {
          label: 'Popisy pozície',
          onClick: () => navigate(EvidenceBrowserUrl.DICT_EMPLOYMENT_POSITION),
          href: EvidenceBrowserUrl.DICT_EMPLOYMENT_POSITION,
          permission: [Permission.BO_EMPLOYMENT_POSITION_READ],
        },
        {
          label: 'Sprostredkovanie poistenia',
          onClick: () => navigate(EvidenceBrowserUrl.DICT_INSURANCE_BROKERAGE),
          href: EvidenceBrowserUrl.DICT_INSURANCE_BROKERAGE,
          permission: [Permission.BO_INSURANCE_BROKERAGE_READ],
        },
      ],
    },
    {
      label: 'Administrácia',
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.INTERNAL_SETTING,
          EvidenceBrowserUrl.ALOG,
          EvidenceBrowserUrl.NOTIFICATION_TEMPLATE,
        ].find((url) => pathname.includes(url));
      },
      items: [
        {
          label: 'Auditné logy',
          onClick: () => navigate(EvidenceBrowserUrl.ALOG),
          href: EvidenceBrowserUrl.ALOG,
          permission: [Permission.SYSTEM_ADMINISTRATION],
        },
        {
          label: 'Nastavenia aplikácie',
          onClick: () => navigate(EvidenceBrowserUrl.INTERNAL_SETTING),
          href: EvidenceBrowserUrl.INTERNAL_SETTING,
          permission: [Permission.SYSTEM_ADMINISTRATION],
        },
        {
          label: 'Šablóny notifikácií',
          onClick: () => navigate(EvidenceBrowserUrl.NOTIFICATION_TEMPLATE),
          href: EvidenceBrowserUrl.NOTIFICATION_TEMPLATE,
          permission: [Permission.SYSTEM_ADMINISTRATION],
        },
      ],
    },
  ]);

  const userBtnActions: UserBtnAction[] = useMemo(
    () => [
      {
        label: 'Profil',
        action: () => {
          navigate(EvidenceBrowserUrl.PROFILE);
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const filterItems = (items: UdvaMenuItem[]) => {
    if (!user) {
      return [];
    }

    const getNodes = (result: UdvaMenuItem[], item: UdvaMenuItem) => {
      if (!item.items && !item.permission) {
        result.push(item);
        return result;
      }

      const permitted = (item?.permission ?? []).some((perm) => {
        return hasPermission(perm);
      });

      if (permitted) {
        result.push(item);
        return result;
      }

      if (Array.isArray(item.items)) {
        const items = item.items.reduce(getNodes, []);
        if (items.length) result.push({ ...item, items });
      }

      return result;
    };

    return items.reduce(getNodes, []);
  };

  const filteredItems = useMemo(() => filterItems(items), [loading]);

  return { items, userBtnActions, filteredItems, menuLoading: loading };
}
