import React, { useContext } from 'react';
import {
  TableFieldCells,
  TableFieldColumn,
  UserContext,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { DictEmploymentPosition, Employment, Subject } from '@models';
import { EvidenceBrowserUrl, Permission } from '@enums';

export function useColumns(
  display: 'IN_AUDITOR' | 'IN_AUDIT_COMPANY' | 'IN_SUBJECT',
  getLicenceNumber?: (subject?: Subject) => string
): TableFieldColumn<Employment>[] {
  const { hasPermission } = useContext(UserContext);

  const subjectRedirectColumn: TableFieldColumn<Employment> = {
    datakey: 'subject.id',
    name: '',
    width: 70,
    CellComponent: function Cell({ value }) {
      return <Redirect id={value} url={EvidenceBrowserUrl.NATURAL_PERSON} />;
    },
  };

  const subjectColumn = {
    datakey: 'subject.label',
    name: 'Zamestnanec',
    width: 250,
  };

  const licenceColumn: TableFieldColumn<Employment> = {
    datakey: 'auditor.slovakLicence.label',
    name: 'Licencia',
    width: 200,
    CellComponent: function Cell({ value, ...rest }) {
      if (getLicenceNumber) {
        return <div>{getLicenceNumber(rest.rowValue?.subject)}</div>;
      }
      return <div>{value}</div>;
    },
  };

  const auditCompanyRedirectColumn: TableFieldColumn<Employment> = {
    datakey: 'auditCompany.id',
    name: '',
    width: 70,
    CellComponent: function Cell({ value }) {
      return <Redirect id={value} url={EvidenceBrowserUrl.AUDIT_COMPANY} />;
    },
  };

  const auditCompanyColumn = {
    datakey: 'auditCompany.label',
    name: 'Zamestnávateľ',
    width: 250,
  };

  const columns: TableFieldColumn<Employment>[] = [
    {
      datakey: 'positions',
      name: 'Popis pozície',
      width: 250,
      CellComponent: ({ value, ...props }) => (
        <TableFieldCells.TextCell
          {...props}
          value={value?.map((v: DictEmploymentPosition) => v?.label).join(', ')}
        />
      ),
    },
    {
      datakey: 'employeeManagement',
      name: 'Správa audítorov',
      width: 150,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      datakey: 'insuranceManagement',
      name: 'Správa poistenií',
      width: 150,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      datakey: 'auditReporting',
      name: 'Zápis auditov a ich hlásenie na UDVA',
      width: 200,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      datakey: 'note',
      name: 'Poznámka',
      width: 200,
    },
  ];

  if (display !== 'IN_AUDIT_COMPANY') {
    if (hasPermission(Permission.BO_AUDITING_ENTITY_READ)) {
      return [auditCompanyRedirectColumn, auditCompanyColumn, ...columns];
    }

    return [auditCompanyColumn, ...columns];
  } else if (display === 'IN_AUDIT_COMPANY') {
    if (hasPermission(Permission.BO_SUBJECT_READ)) {
      return [subjectRedirectColumn, subjectColumn, licenceColumn, ...columns];
    }

    return [subjectColumn, ...columns];
  }

  return [subjectColumn, auditCompanyColumn, ...columns];
}
