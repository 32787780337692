import React, { ComponentType, useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormCheckbox,
  FormContext,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useLegalPersons } from '@modules/subject/subject-api';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditingEntityAutocomplete,
  FinancialStatementAudit,
  SubjectAutocomplete,
} from '@models';
import { EvidenceApiUrl, EvidenceBrowserUrl, Permission } from '@enums';

export function Fields({
  companyId,
  disabledAuditingEntities,
  Subject,
}: {
  companyId?: string;
  disabledAuditingEntities?: boolean;
  Subject?: ComponentType;
}) {
  const { hasPermission } = useContext(UserContext);
  const { mode } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);
  const { publicInterestEntityCategories } = useContext(StaticDataContext);

  const { publicInterest, tradingCompany, auditedUnit } = useFormSelector(
    (data: FinancialStatementAudit) => ({
      publicInterest: data.publicInterest,
      tradingCompany: data.tradingCompany,
      auditedUnit: data.auditedUnit,
    })
  );

  /*const auditors = useAuditors();
  const companyAuditors = useCompanyAuditors(companyId);*/
  const auditingEntities = useAuditingEntities();
  const legalPersons = useLegalPersons(EvidenceApiUrl.SUBJECT);
  const categorySource = useStaticListSource(publicInterestEntityCategories);

  return (
    <>
      <FormPanel label="Účtovná závierka">
        <FormAutocomplete<AuditingEntityAutocomplete>
          name="auditingEntities"
          label="Vykonávatelia auditu"
          source={auditingEntities}
          labelMapper={autocompleteLabelMapper}
          multiline
          multiple
          required
          disabled={disabledAuditingEntities}
        />
        <FormTextField
          name="auditCalendarYear"
          label="Rok vykonania auditu"
          type="number"
        />
        <FormLocalDateField
          name="auditedUnitAccountingEndDate"
          label="Koniec účtovného obdobia účtovnej jednotky, za ktorého bol vykonaný audit"
          required
        />
        <FormLocalDateField
          name="auditingEntityAccountingEndDate"
          label="Koniec účtovného obdobia vykonávateľa auditu, za ktorého bol vykonaný audit"
          required
        />
        <FormCheckbox
          name="consolidated"
          label="Konsolidovaná účtovná závierka"
        />
        <FormCheckbox name="individual" label="Individuálna účtovná závierka" />
        <FormCheckbox
          name="tradingCompany"
          label="Obchodná spoločnosť"
          notifyChange={(value) => {
            if (!value && !publicInterest) {
              setFieldValue('auditStartYear', undefined);
            }
          }}
        />
        <FormCheckbox
          name="publicInterest"
          label="Subjekt verejného záujmu"
          notifyChange={(value) => {
            if (!value && !tradingCompany) {
              setFieldValue('auditStartYear', undefined);
            }

            if (!value) {
              setFieldValue('category', undefined);
              setFieldValue('subjectAuditProfits', undefined);
            }
          }}
        />
        {(publicInterest || tradingCompany) && (
          <FormTextField
            type="number"
            name="auditStartYear"
            label="Audit v SVZ/OS vykonávaný od roku"
            disabled={!publicInterest && !tradingCompany}
            required={!!publicInterest || !!tradingCompany}
          />
        )}
        {publicInterest && (
          <FormSelect
            source={categorySource}
            name="category"
            label="Kategórie subjektu verejného záujmu"
            valueIsId
            disabled={!publicInterest}
            required={!!publicInterest}
          />
        )}
      </FormPanel>
      <FormPanel label="Účtovná jednotka">
        {Subject ? (
          <Subject />
        ) : (
          <FormAutocomplete<SubjectAutocomplete>
            source={legalPersons}
            label="Subjekt"
            name="auditedUnit"
            labelMapper={autocompleteLabelMapper}
            required
            after={
              auditedUnit?.id &&
              mode === DetailMode.VIEW &&
              hasPermission(Permission.BO_SUBJECT_READ) && (
                <Redirect
                  url={EvidenceBrowserUrl.LEGAL_PERSON}
                  id={auditedUnit.id}
                />
              )
            }
          />
        )}
        <FormTextField
          name="auditedUnit.identifier"
          notifyChange={(value) => setFieldValue('auditedUnit', value)}
          disabled
          required={false}
        />
        <FormTextField
          name="auditedUnit.businessName"
          label="Názov spoločnosti"
          disabled
        />
        <FormTextField
          name="auditedUnit.address.label"
          label="Adresa"
          disabled
        />
      </FormPanel>
      {!!publicInterest && (
        <FormPanel label="Tržby">
          <FormTextField
            type="number"
            name="subjectAuditProfits.statutoryAudit"
            label="Tržby za štatutárny audit"
            required
          />
          <FormTextField
            type="number"
            name="subjectAuditProfits.nonAuditServicesB"
            label="Tržby za neaudítorské služby podľa čl. 14 b) Nariadenia č. 537/2014"
            required
          />
          <FormTextField
            type="number"
            name="subjectAuditProfits.nonAuditServicesC"
            label="Tržby za neaudítorské služby podľa čl. 14 c) Nariadenia č. 537/2014"
            required
          />
          <FormCheckbox
            name="subjectAuditProfits.auditCommittee"
            label="Výbor pre audit"
          />
          {mode === DetailMode.VIEW && (
            <FormTextField
              type="number"
              name="subjectAuditProfits.total"
              label="Tržby celkom"
              disabled
            />
          )}
        </FormPanel>
      )}
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}
