/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  AdminProvider,
  DetailToolbar2,
  EvidenceDefaultsProvider,
  ExportProvider,
  FilesProvider,
  InactivityProvider,
  Menubar,
  NavigationContext,
  NotificationButton,
  NotificationProvider,
  UserContext,
  UserSettingsProvider,
  useFirstRender,
} from '@eas/common-web';
import { useExportTags } from '@modules/export/export-api';
import { StaticDataProvider } from '@components/evidence/static-data-provider';
import { useMenuItems } from '@components/menu/menu-items';
import { SecureRoute } from '@components/router/secure-route';
import { Permission } from '@enums';
import { EvidenceApiUrl, EvidenceBrowserUrl } from './enums/evidence-url';
import logo from './logo.svg';
import * as Module from './modules';

export const useStyles = makeStyles({
  logoWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  logo: {
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
  },
  title: {
    fontWeight: 400,
    fontSize: 12,
    color: 'white',
    marginLeft: 10,
  },
  userButton: {
    fontSize: 12,
  },
  subMenuItem: {
    margin: 0,
  },
  subMenuItemIcon: {
    minWidth: 25,
  },
});

export function AppSecure() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { filteredItems, userBtnActions, menuLoading } = useMenuItems();
  const exportTags = useExportTags();

  const { navigate } = useContext(NavigationContext);
  const { user } = useContext(UserContext);

  useFirstRender(async () => {
    const redirectToHomePage = () => {
      if (pathname) {
        window.sessionStorage.setItem('redirectTo', pathname);
      }
      navigate('/', true);
    };

    if (!user) {
      redirectToHomePage();
    } else {
      const redirectTo = window.sessionStorage.getItem('redirectTo');
      window.sessionStorage.removeItem('redirectTo');

      if (redirectTo) {
        navigate(redirectTo, true);
      }
    }
  });

  if (menuLoading) {
    return <></>;
  }

  return (
    <InactivityProvider timeout={1800}>
      <EvidenceDefaultsProvider
        appName="UDVA"
        appLogo={logo}
        enableEmptyDetailContainer={true}
        ToolbarComponent={DetailToolbar2}
      >
        <ExportProvider url="/api/udva/export" tags={exportTags} disableAsync>
          <UserSettingsProvider url="/api/udva/user-settings">
            <NotificationProvider
              notificationUrl={EvidenceApiUrl.NOTIFICATION}
              notificationTemplateUrl={EvidenceApiUrl.NOTIFICATION_TEMPLATE}
              ruleUrl={`${EvidenceApiUrl.NOTIFICATION_TEMPLATE}/rule`}
              notificationEventUrl={EvidenceApiUrl.NOTIFICATION_EVENT}
              notificationPreferenceUrl={EvidenceApiUrl.NOTIFICATION_PREFERENCE}
              notificationSubscriptionTypeUrl={`${EvidenceApiUrl.NOTIFICATION_PREFERENCE}/subscription/type`}
            >
              <FilesProvider url="/api/udva/files">
                <StaticDataProvider>
                  <Menubar
                    logoUrl="/udva"
                    displayLogoutBtn={true}
                    logo={
                      <div className={classes.logoWrapper}>
                        <img src={logo} className={classes.logo} />
                      </div>
                    }
                    title={
                      <Typography className={classes.title}>
                        UDVA - Centrálna evidencia zostáv
                      </Typography>
                    }
                    items={filteredItems}
                    userBtnActions={userBtnActions}
                    classOverrides={{
                      userButton: classes.userButton,
                      subMenuItem: classes.subMenuItem,
                      subMenuItemIcon: classes.subMenuItemIcon,
                    }}
                    beforeUserBtn={<NotificationButton />}
                  >
                    <AdminProvider
                      prefix="/udva"
                      reindexUrl="/api/udva/reindex"
                      permission={Permission.SYSTEM_ADMINISTRATION}
                    />
                    <Switch>
                      <Route path={EvidenceBrowserUrl.HOME} exact component={Module.Dashboard} />
                      <SecureRoute path={`${EvidenceBrowserUrl.ALOG}/:id?`} component={Module.Alog} permissions={[Permission.SYSTEM_ADMINISTRATION]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.AUDITOR}/:id?`} component={Module.Auditors} permissions={[Permission.BO_AUDITING_ENTITY_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.AUDIT_COMPANY}/:id?`} component={Module.AuditCompanies} permissions={[Permission.BO_AUDITING_ENTITY_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.AUDIT_NOTICE}/:id?`} component={Module.AuditNotices} permissions={[Permission.BO_AUDIT_NOTICE_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.AUDIT_REGISTRATION}/:id?`} component={Module.AuditRegistrations} permissions={[Permission.BO_AUDIT_REGISTRATION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.AUDIT_REPORT}/:id?`} component={Module.AuditReports} permissions={[Permission.BO_AUDIT_REPORT_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.CALENDAR_EVENT}/:id?`} component={Module.CalendarEvents} permissions={[Permission.BO_CALENDAR_EVENT_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.CERTIFICATION}/:id?`} component={Module.Certifications} permissions={[Permission.BO_CERTIFICATION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.CHARGE}/:id?`} component={Module.Charges} permissions={[Permission.BO_CHARGE_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.DICT_COUNTRY}/:id?`} component={Module.DictCountries} permissions={[Permission.BO_COUNTRY_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.DICT_EDUCATION_FIELD}/:id?`} component={Module.DictEducationFields} permissions={[Permission.BO_EDUCATION_MANAGE]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.DICT_EDUCATION_FORM}/:id?`} component={Module.DictEducationForms} permissions={[Permission.BO_EDUCATION_MANAGE]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.DICT_EMPLOYMENT_POSITION}/:id?`} component={Module.DictEmploymentPositions} permissions={[Permission.BO_EMPLOYMENT_POSITION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.DICT_INSURANCE_BROKERAGE}/:id?`} component={Module.DictInsuranceBrokerages} permissions={[Permission.BO_INSURANCE_BROKERAGE_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.DICT_INSURANCE_COMPANY}/:id?`} component={Module.DictInsuranceCompanies} permissions={[Permission.BO_INSURANCE_COMPANY_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.DICT_MONETARY_CURRENCY}/:id?`} component={Module.DictMonetaryCurrencies} permissions={[Permission.BO_CURRENCY_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.DOCUMENT}/:id?`} component={Module.Documents} permissions={[Permission.BO_DOCUMENT_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.EDUCATION}/:id?`} component={Module.Educations} permissions={[Permission.BO_EDUCATION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.EDUCATION_CYCLE}/:id?`} component={Module.EducationCycles} permissions={[Permission.BO_EDUCATION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.EDUCATION_OVERVIEW}/:id?`} component={Module.EducationOverview} permissions={[Permission.BO_EDUCATION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.EDUCATION_SUBMISSION}/:id?`} component={Module.EducationSubmissions} permissions={[Permission.BO_EDUCATION_SUBMISSION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.EDUCATIONAL_TRAINING}/:id?`} component={Module.EducationalTrainings} permissions={[Permission.BO_EDUCATION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.EXAM_APPLICATION}/:id?`} component={Module.ExamApplications} permissions={[Permission.BO_EXAM_APPLICATION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.EXAM}/:id?`} component={Module.Exams} permissions={[Permission.BO_EXAM_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.FINANCIAL_STATEMENT_AUDIT}/:id?`} component={Module.FinancialStatementAudits} permissions={[Permission.BO_FIN_STATEMENT_AUDIT_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.GENERAL_SUBMISSION}/:id?`} component={Module.GeneralSubmissions} permissions={[Permission.BO_GENERAL_SUBMISSION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.ANONYMOUS_SUBMISSION}/:id?`} component={Module.AnonymousSubmissions} permissions={[Permission.BO_ANONYMOUS_SUBMISSION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.INSURANCE}/:id?`} component={Module.Insurances} permissions={[Permission.BO_INSURANCE_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.INSURANCE_SUBMISSION}/:id?`} component={Module.InsuranceSubmissions} permissions={[Permission.BO_INSURANCE_SUBMISSION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.INTERNAL_SETTING}/:id?`} component={Module.InternalSettings} permissions={[Permission.SYSTEM_ADMINISTRATION]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.LEGAL_PERSON}/:id?`} component={Module.LegalPersons} permissions={[Permission.BO_SUBJECT_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.LICENCE}/:id?`} component={Module.Licences} permissions={[Permission.BO_LICENCE_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.LICENCE_APPLICATION}/:id?`} component={Module.LicenceApplications} permissions={[Permission.BO_LICENCE_APPLICATION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.NATURAL_PERSON}/:id?`} component={Module.NaturalPersons} permissions={[Permission.BO_SUBJECT_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.NOTIFICATION}/:id?`} component={Module.Notifications} permissions={[Permission.SYSTEM_ADMINISTRATION]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.NOTIFICATION_TEMPLATE}/:id?`} component={Module.NotificationTemplates} permissions={[Permission.SYSTEM_ADMINISTRATION]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.PAYMENT}/:id?`} component={Module.Payments} permissions={[Permission.BO_PAYMENT_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.PAYMENT_PARSE}/:id?`} component={Module.PaymentsParse} permissions={[Permission.BO_PAYMENT_READ]} />
                      <Route path={`${EvidenceBrowserUrl.PROFILE}/:id?`} component={Module.Profile} />
                      <SecureRoute path={`${EvidenceBrowserUrl.QUALIFICATION}/:id?`} component={Module.Qualifications} permissions={[Permission.BO_QUALIFICATION_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.REGISTRY_RECORD}/:id?`} component={Module.RegistryRecords} permissions={[Permission.BO_REGISTRY_RECORD_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.ROLE}/:id?`} component={Module.Roles} permissions={[Permission.BO_USER_ROLE_READ]} />
                      <SecureRoute path={`${EvidenceBrowserUrl.USER}/:id?`} component={Module.Users} permissions={[Permission.BO_UDVA_USER_READ]} />
                    </Switch>
                  </Menubar>
                </StaticDataProvider>
              </FilesProvider>
            </NotificationProvider>
          </UserSettingsProvider>
        </ExportProvider>
      </EvidenceDefaultsProvider>
    </InactivityProvider>
  );
}
