import React, { ComponentType } from 'react';
import { FormAutocomplete, FormPanel, FormTextField } from '@eas/common-web';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { DictCountryAutocomplete } from '@models';
import { autocompleteLabelMapper } from '../misc/label-mappers';

interface AddressPanelProps {
  name?: string;
  label?: string;
  defaultExpanded?: boolean;
  After?: ComponentType;
  required?: boolean;
  disabled?: boolean;
}

export function FormAddressPanel({
  name = 'address',
  label = 'Adresa',
  defaultExpanded = true,
  After,
  required = false,
  disabled = false,
}: AddressPanelProps) {
  return (
    <FormPanel
      label={label}
      expandable={true}
      defaultExpanded={defaultExpanded}
    >
      <AddressFields
        name={name}
        After={After}
        required={required}
        disabled={disabled}
      />
    </FormPanel>
  );
}

export function AddressFields({
  name,
  After,
  required,
  disabled,
}: Omit<AddressPanelProps, 'label' | 'defaultExpanded'>) {
  const countries = useCountries();

  const prefix = name ? `${name}.` : '';

  return (
    <>
      <FormTextField
        label="Ulica"
        name={`${prefix}street`}
        required={false}
        disabled={disabled}
      />
      <FormTextField
        label="Číslo budovy"
        name={`${prefix}houseNumber`}
        required={required}
        disabled={disabled}
      />
      <FormTextField
        label="PSČ"
        name={`${prefix}zip`}
        required={required}
        disabled={disabled}
      />
      <FormTextField
        label="Obec"
        name={`${prefix}municipality`}
        required={required}
        disabled={disabled}
      />
      <FormAutocomplete<DictCountryAutocomplete>
        source={countries}
        label="Krajina"
        name={`${prefix}country`}
        required={required}
        disabled={disabled}
        labelMapper={autocompleteLabelMapper}
      />
      <FormTextField
        label="P.O. Box"
        name={`${prefix}poBox`}
        disabled={disabled}
      />
      {After && <After />}
    </>
  );
}
